import React from 'react';
import s from './InfoSectionDescription.scss';
import cx from 'classnames';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';

export enum DataHook {
  Description = 'info-section-description',
}
export interface InfoSectionDescriptionProps extends ProvidedGlobalProps {
  description: string;
  index: number;
}

@withGlobalProps
export class InfoSectionDescription extends React.Component<InfoSectionDescriptionProps> {
  public render(): JSX.Element {
    const {description, index} = this.props;
    const {experiments} = this.props.globals;
    const descriptionClasses = cx(s.description, {[s.rtlFix]: experiments.infoSectionDescriptionRtlBulletsFix});

    return (
      <div
        key={index}
        data-hook={DataHook.Description}
        className={descriptionClasses}
        dangerouslySetInnerHTML={{__html: description}}
      />
    );
  }
}
